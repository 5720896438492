import { useNavigate } from "react-router";
import { Clickable } from "./Clickable";

export function CourseOptions() {

    const navigate = useNavigate()

    return <div className="separate-h border-b-1-black">
        <div className=""></div>
        <div className="flex-row border-l-1-black">
            <Clickable onPress={() => {
                navigate('/course-login')
            }}>
                <p className="p">log in</p>
            </Clickable>
            <Clickable onPress={() => {
                navigate('/course-signup')
            }}>
                <p className="blue p border-l-1-black">sign up</p>
            </Clickable>
        </div>
    </div>
}