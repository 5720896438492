import { firebase_CreateDocument } from "react-library/src/firebase";
import { Clickable } from "../../UTILITIES/Clickable";
import { db } from "../../firebase";
import { randomString } from "react-library/src/FUNCTIONS/misc";
import { useState } from "react";
import { Loading } from "../../UTILITIES/Loading";

export function CoursePost() {
    const [loading, setLoading] = useState(false);

    async function onPost() {
        const title = document.querySelector('#tbTitle').value;
        const post = document.querySelector('#taPost').value;
        const tags = document.querySelector('#tbTags').value;

        if (title == "" || post == "" || tags == "") {
            alert("Please fill out all fields.");
            return;
        }

        setLoading(true);
        await firebase_CreateDocument(db, 'CoursePosts', randomString(25), {
            title: title,
            post: post.replaceAll('\n', 'jjj'),
            tags: tags.split(','),
            date: new Date().getTime()
        }, (success) => {
            if (success) {
                document.querySelector('#tbTitle').value = "";
                document.querySelector('#taPost').value = "";
                document.querySelector('#tbTags').value = "";
                alert("Posted Successfully")
                setLoading(false);
            }
        })
    }

    return <div className="space">
        {loading && <Loading />}
        <div className="main">
            <div className="p">
                <h1>Course Forum Post</h1>
            </div>
            <br />
            <br />
            <div className="row p">
                <div className="md2">
                    <p>title</p>
                    <div className="border-b-1-black">
                        <input type="text" id="tbTitle" placeholder="ex. New updates to course" className="no-outline p-v full-width no-border space" />
                    </div>
                    <br />
                    <p>post</p>
                    <div className="border-b-1-black">
                        <textarea style={{ minHeight: 300 }} id="taPost" className="no-outline p-v space no-border full-width" placeholder="ex. Type your post here.."></textarea>
                    </div>
                    <br />
                    <p>tags</p>
                    <div className="border-b-1-black">
                        <input type="text" id="tbTags" placeholder="comma separated" className="no-outline p-v full-width no-border space" />
                    </div>
                    <br />
                    <br />
                    <div className="separate-h">
                        <div></div>
                        <div>
                            <Clickable onPress={() => {
                                onPost();
                            }}>
                                <div className="bg-black full-radius p-sm">
                                    <p className="white m-h">post on forum</p>
                                </div>
                            </Clickable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}