import { useEffect, useState } from "react";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { auth_CheckUser, auth_SignOut, firebase_GetAllDocuments, firebase_GetAllDocumentsQueried, firebase_GetDocument } from 'react-library/src/firebase.js'
import { auth, db } from "../firebase";
import { Clickable } from "../UTILITIES/Clickable";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router";
import { UserOptions } from "../UTILITIES/UserOptions";
import { CourseOptions } from "../UTILITIES/CourseOptions";
import { Loading } from "../UTILITIES/Loading";
import { MdArrowOutward } from "react-icons/md";

export function Courses() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [me, setMe] = useState(null)

    async function onLoad() {
        setLoading(true)
        await firebase_GetAllDocuments(db, 'Courses', (docs) => {
            setCourses(docs)
        })
        await auth_CheckUser(auth, async (user) => {
            console.log(user)
            if (user !== null) {
                setLoggedIn(true)
                await firebase_GetDocument(db, 'CourseUsers', user.uid, (doc) => {
                    if (doc !== null) {
                        setMe(doc)
                    } else {
                        auth_SignOut(auth, (succ) => {
                            navigate('/course-login')
                        })
                    }
                })
            }
        })
        setLoading(false)
    }

    useEffect(() => {
        document.title = 'Our Courses - Innovative Internet Creations'
        onLoad();
    }, [])


    return <div className="space">
        {loading && <Loading />}
        <Navigation />
        {loggedIn && <UserOptions />}
        {!loggedIn && <CourseOptions />}
        <div className="main">
            <div className="row p border-b-1-black box">
                <div className="md2">
                    <h1 className="upper">Courses</h1>
                    <p>Our mission is to teach the world the most effective and straightforward ways to achieve goals. We specialize in creating practical solutions for both life and business. Forget unnecessary fillers or outdated lessons—everything we offer is current and relevant. Plus, we provide powerful tools to simplify learning and execution, making your journey smoother and more efficient.</p>
                </div>
                <div className="md2">
                    <div className="separate-h">
                        <div></div>
                        <div>
                            <Clickable onPress={() => {
                                navigate('/course-forum')
                            }}>
                                <div className="flex-row gap vertical-center blue">
                                    <h2 className="underline">Course Forum</h2>
                                    <MdArrowOutward size={30} />
                                </div>
                            </Clickable>
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div className="gap box">
                {
                    courses.map((course, i) => {
                        return <div className="box border-b-1-black p-lg" key={i}>
                            <h2>{course.name}</h2>
                            <br />
                            <p>{course.desc}</p>
                            <br />
                            <div className="separate-h">
                                <div>
                                </div>
                                <Clickable onPress={() => {
                                    navigate(`/course/${course.id}`)
                                }}>
                                    <div className="flex-row gap vertical-center">
                                        <h3>View Course</h3><FaArrowRight size={20} />
                                    </div>
                                </Clickable>
                            </div>
                        </div>
                    })
                }
            </div>

        </div>

        <Footer />
    </div>
}