import { auth_CheckUser, firebase_CreateDocument, firebase_GetAllDocumentsQueried, firebase_GetDocument, storage_DownloadMedia } from "react-library/src/firebase";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { auth, db, storage } from "../../firebase";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Loading } from "../../UTILITIES/Loading";
import { FaArrowLeft } from "react-icons/fa6";
import { Clickable } from "../../UTILITIES/Clickable";
import { randomString } from "react-library/src/FUNCTIONS/misc";

export function CourseVideo() {
    const { lessonId } = useParams();
    const navigate = useNavigate();
    const [lesson, setLesson] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoWatched, setVideoWatched] = useState(false);
    const [me, setMe] = useState(null)

    async function onLoad() {
        await auth_CheckUser(auth, async (user) => {
            if (user !== null) {
                await firebase_GetDocument(db, 'CourseUsers', user.uid, async (doc) => {
                    if (doc !== null) {
                        setMe(doc)

                        await firebase_GetAllDocumentsQueried(db, 'CourseViews', [
                            { field: 'userId', operator: '==', value: user.uid },
                            { field: 'lessonId', operator: '==', value: lessonId }
                        ], (docs) => {
                            if (docs.length > 0) {
                                setVideoWatched(true)
                            }
                        })
                    }
                })
            } else {
                navigate('/course-login')
            }
        })
        await firebase_GetDocument(db, 'Lessons', lessonId, async (doc) => {
            setLesson(doc);
            document.title = `${doc.name} - Innovative Internet Creations`;
            await storage_DownloadMedia(storage, doc.videoPath, (url) => {
                setVideoUrl(url);
            });
        });
    }

    async function onWatchedVideo() {
        setVideoWatched(true)
        await firebase_CreateDocument(db, 'CourseViews', randomString(25), {
            userId: me.id,
            lessonId: lesson.id,
            courseId: lesson.courseId
        }, (success) => {
            if (success) {
                console.log("WATCHED")
            }
        });
    }

    useEffect(() => {
        onLoad();
    }, []);

    if (lesson !== null && videoUrl !== null) {
        return (
            <div className="space">
                <Navigation />
                <div className="main">
                    <div className="p flex-row gap vertical-center">
                        <Clickable onPress={() => navigate(`/course/${lesson.courseId}`)}>
                            <FaArrowLeft size={20} />
                        </Clickable>
                        <h2>{lesson.name}</h2>
                    </div>
                    {videoWatched && (
                        <div>
                            <div className="p bg-green radius">
                                <h3 className="white">Congratulations!</h3>
                                <p className="white">You have completed this video.</p>
                            </div>
                            <br />
                        </div>
                    )}

                    <div className="bg-black">
                        <video
                            controls
                            src={videoUrl}
                            style={{ width: "100%", height: "75vh" }}
                            onContextMenu={(e) => e.preventDefault()}
                            onEnded={onWatchedVideo} // Trigger when video ends
                        />
                    </div>
                    <br />
                    {lesson.note !== "" && (
                        <div className="p bg-orange radius">
                            <h3 className="white">Note</h3>
                            <p
                                className="white"
                                dangerouslySetInnerHTML={{
                                    __html: lesson.note.replaceAll("jjj", "<br />"),
                                }}
                            ></p>
                        </div>
                    )}
                    {lesson.desc !== "" && (
                        <div className="p">
                            <h3>Details</h3>
                            <p
                                className=""
                                dangerouslySetInnerHTML={{
                                    __html: lesson.desc.replaceAll("jjj", "<br />"),
                                }}
                            ></p>
                        </div>
                    )}

                    <br />
                </div>
                <Footer />
            </div>
        );
    } else {
        return <Loading />;
    }
}