import { LuLogOut } from "react-icons/lu";
import { Clickable } from "./Clickable";
import { auth_CheckUser, auth_SignOut, firebase_GetDocument } from "react-library/src/firebase";
import { auth, db } from "../firebase";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";

export function UserOptions() {
    const navigate = useNavigate()
    const [email, setEmail] = useState(null)
    const currentPage = useLocation()

    async function onLoad() {
        await auth_CheckUser(auth, async (user) => {
            if (user !== null) {
                await firebase_GetDocument(db, 'CourseUsers', user.uid, (doc) => {
                    setEmail(doc.email)
                })
            }
        })
    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="separate-h">
        <div>
            {
                currentPage.pathname != '/courses' && <Clickable onPress={() => {
                    navigate('/courses')
                }}><p className="p">back</p></Clickable>
            }
        </div>
        <div className="p flex-row gap">
            {email !== null && <p>{email}</p>}
            <Clickable onPress={async () => {
                await auth_SignOut(auth, (success) => {
                    if (success) {
                        navigate('/course-login')
                    }
                })
            }}>
                <LuLogOut color="red" size={24} />
            </Clickable>
        </div>
    </div>
}