import { useNavigate } from "react-router";
import { Clickable } from "../../UTILITIES/Clickable";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { auth_CreateUser, firebase_CreateDocument } from "react-library/src/firebase";
import { auth, db } from "../../firebase";
import { useEffect, useState } from "react";
import { Loading } from "../../UTILITIES/Loading";

export function CourseSignUp() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    async function onSignUp() {
        const email = document.querySelector('#tbEmail').value;
        const password = document.querySelector('#tbPassword').value;
        const passwordConf = document.querySelector('#tbConfirmPassword').value;

        if (email == "" || password == "" || passwordConf == "") {
            alert('Please fill out all fields.');
            return;
        }

        if (password !== passwordConf) {
            alert("Make sure your passwords match.");
            return;
        }

        const args = {
            email: email,
        }

        await auth_CreateUser(auth, email, password, async (user) => {
            if (user !== null) {
                await firebase_CreateDocument(db, 'CourseUsers', user.uid, args, (success) => {
                    if (success) {
                        navigate('/courses')
                    } else {
                        alert("There was an error. Try again later.")
                    }
                })
            } else {
                alert("There was an error. Try again later or use a different email.")
                return;
            }
        })

    }

    useEffect(() => {
        document.title = 'Course Signup - Innovative Internet Creations'
    }, []);

    return <div className="space">
        {loading && <Loading />}
        <Navigation />
        <div className="main">
            <div className="p-lg full-width" style={{ margin: '0 auto', maxWidth: 300 }}>
                <h1 className="normal">Sign Up</h1>
                <br />
                <p>email</p>
                <div className="border-b-1-black">
                    <input type="email" id="tbEmail" placeholder="ex. iicdev@gmail.com" className="no-border no-outline p-v space full-width" />
                </div>
                <br />
                <p>password</p>
                <div className="border-b-1-black">
                    <input type="password" id="tbPassword" placeholder="minimum 8 chars" className="no-border no-outline p-v space full-width" />
                </div>
                <br />
                <p>confirm password</p>
                <div className="border-b-1-black">
                    <input type="password" id="tbConfirmPassword" placeholder="minimum 8 chars" className="no-border no-outline p-v space full-width" />
                </div>
                <br />
                <div className="separate-h">
                    <div></div>
                    <div className="">
                        <Clickable onPress={() => {
                            onSignUp()
                        }}>
                            <div className="bg-black p-sm full-radius">
                                <p className="m-h white">sign up</p>
                            </div>
                        </Clickable>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}