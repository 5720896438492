import { useEffect, useState } from "react";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { firebase_GetAllDocuments } from "react-library/src/firebase";
import { db } from "../../firebase";
import { Loading } from "../../UTILITIES/Loading";

export function CourseForum() {
    const [loading, setLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    async function onLoad() {
        setLoading(true)
        await firebase_GetAllDocuments(db, 'CoursePosts', (docs) => {
            setPosts(docs)
            setLoading(false)
        })
    }
    useEffect(() => {
        document.title = 'Course Forum - Innovative Internet Creations'
        onLoad();
    }, [])

    return <div className="space">
        {loading && <Loading />}
        <Navigation />

        <div className="main">
            {
                posts.map((post, i) => {
                    return <div className="border-b-1-black" key={i}>
                        <div className="p-lg">
                            <div className="separate-h box">
                                <h3>{post.title}</h3>
                                <p>{new Date(post.date).toDateString()}</p>
                            </div>
                            <br />
                            <div className="box">
                                <p dangerouslySetInnerHTML={{ __html: post.post.replaceAll('jjj', '<br />') }}></p>
                            </div>
                            <br />
                            <div className="scroll-container" style={{ maxWidth: '100vw', overflowX: 'auto', whiteSpace: 'nowrap', height: '50px' }}>
                                <div className="flex-row gap scrollX" style={{ display: 'flex', flexWrap: 'nowrap' }}>
                                    {
                                        post.tags.map((tag, j) => {
                                            return (
                                                <div className="no-wrap p-sm bg-black full-radius fit-height" key={j} style={{ flexShrink: 0 }}>
                                                    <p className="text-xs m-h white">{tag}</p>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>

        <Footer />
    </div>
}