import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import nationalCityLogo from '../IMAGES/national-city-logo.jpg'
import downeyLogo from '../IMAGES/downey-logo.jpg'
import genkiMangaLogo from '../IMAGES/genkimanga-logo.png'
import sdgaLogo from '../IMAGES/sdga-logo.jpg'
import adsMayhemLogo from '../IMAGES/ads-mayhem-logo.png'
import wandermateLogo from '../IMAGES/wandermate-logo.png'
import praticeNowLogo from '../IMAGES/practice-now-logo.png'
import { useNavigate } from "react-router";
import { Clickable } from "../UTILITIES/Clickable";
import { useEffect } from "react";

export function Projects() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Our Projects - Innovative Internet Creations'
    }, []);

    return <div className="space">
        <Navigation />
        <div className="main">
            <div className="row p box">
                <h1 className="upper text-lg line-sm">Our Projects</h1>
            </div>
            <div className="p box border-b-1-black box">
                <div className="">
                    <p>We specialize in creating solutions that truly serve people. Whether you’re an individual, a business, an organization, or an institution, we are committed to leveraging technology in smart and innovative ways—setting us apart from the rest.</p>
                </div>

            </div>
            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">Ads Mayhem</h1>
                    <p>User & Business Apps ver. 2.0</p>
                    <br />
                    <p>Ads belong where people want to see them. Our platform showcases ads and coupons to users who actively seek them, boosting engagement and increasing purchase likelihood.</p>
                    <br />
                    <p>For more information, check out the app details and links.</p>
                    <br />

                    <Clickable onPress={() => { navigate('/apps') }}><p className="underline">Learn More</p></Clickable>


                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={adsMayhemLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>

            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">Pet Grooming Edu</h1>
                    <p>Student Web Portal ver. 2.0</p>
                    <br />
                    <p>The San Diego Grooming Academy offers students a streamlined online portal where they can access lessons, complete homework, and take tests—all in one place. It’s the most efficient and comprehensive way to master the essentials of pet grooming.</p>
                    <br />
                    <p>Course content is developed by Myke Ross, a renowned pet grooming expert based in San Diego, California.</p>
                    <br />
                    <Clickable onPress={() => { window.open('https://petgroomingedu.web.app', '_blank') }}><p className="underline">Learn More</p></Clickable>


                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={sdgaLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>
            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">Genki Pocket Manga</h1>
                    <p>Mobile App ver. 1.0</p>
                    <br />
                    <p>The Genki Pocket Manga app is a platform designed for manga creators worldwide to share and sell their work to a global audience—without the constraints of traditional publishing. The app transforms manga into a mobile-friendly format, making it effortless for readers to enjoy their favorite stories on the go. Unlike traditional book formats that don’t fit well on a phone screen, Genki Pocket Manga ensures an optimized and seamless reading experience for today’s mobile lifestyle.</p>
                    <br />
                    <p>If you are interested in selling your manga, contact us right away!</p>
                    <br />
                    {/* <Clickable onPress={() => { window.open('https://petgroomingedu.web.app', '_blank') }}><p className="underline">Learn More</p></Clickable> */}


                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={genkiMangaLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>

            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">Wander Passport</h1>
                    <p>Mobile Apps ver. 1.0</p>
                    <br />
                    <p>Traveling just got more exciting with Wander Passport! Connect with fellow travelers or locals, discover events and activities in your destination, manage your itinerary, and much more—all in one amazing app.</p>
                    <br />
                    <p>Launch date is coming soon.</p>
                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={wandermateLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>
            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">Practice Now!</h1>
                    <p>Mobile Apps ver. 1.0</p>
                    <br />
                    <p>The bond between a student and teacher is vital for musical growth. Mastering an instrument takes dedication and consistent practice. Practice Now! bridges the gap by connecting students and teachers through a mobile platform, streamlining assignments, practice sessions, tools, and tracking progress to ensure success.</p>
                    <br />

                    <Clickable onPress={() => { navigate('/apps') }}><p className="underline">Learn More</p></Clickable>

                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={praticeNowLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>
            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">City of National City</h1>
                    <p>Mobile App ver. 1.0</p>
                    <br />
                    <p>The City of National City official mobile app brings city resources into your pocket. An all in one place to have discussions, receive updates, chat with representatives, track events, and so much more; all using the intelligence of data science.</p>
                    <br />
                    <p>As time progresses, cities must evolve to meet the needs of the future. The past should not hold us back, and technology offers the tools to move forward. National City deserves a modern, innovative approach that paves the way for a brighter tomorrow.</p>
                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={nationalCityLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>

            <div className="row p gap box">
                <div className="md2 lg2">
                    <h1 className="upper">City of Downey</h1>
                    <p>Mobile App ver. 1.0</p>
                    <br />
                    <p>The City of Downey’s official mobile app puts city resources right at your fingertips. It’s a centralized hub for discussions, real-time updates, event tracking, communication with representatives, and much more—all powered by the intelligence of data science.</p>
                    <br />
                    <p>As cities grow, they must adapt to meet the demands of the future. Downey has the opportunity to embrace modern technology and innovation, ensuring progress that benefits the entire community while paving the way for a brighter tomorrow.</p>

                </div>
                <div className="lg1 xl2" style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={downeyLogo} style={{ width: '100%', height: '100%', maxWidth: 400 }} className="contain" />
                </div>
            </div>

            <br />
            <br />
        </div>
        <Footer />
    </div>
}