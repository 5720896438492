import { Navigation } from "../UTILITIES/Navigation";
import img1 from '../IMAGES/sdga-logo.jpg'
import img2 from '../IMAGES/wandermate-logo.png'
import img3 from '../IMAGES/practice-now-logo.png'
import img4 from '../IMAGES/blockstobytes-logo.png'
import { Footer } from "../UTILITIES/Footer";
import { Clickable } from "../UTILITIES/Clickable";
import { useNavigate } from "react-router";
import { useEffect } from "react";

export function Home() {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Innovative Internet Creations'
    }, []);

    return <div className="space">
        <Navigation />
        <div className="main">
            <div className="row border-b-1-black">
                <div className="p border-black box">
                    <p className="line-sm text-md">We make websites, apps, and solutions designed for the future.</p>
                </div>
                <div className="sm0 md0 lg1 xl1"></div>
                <div className="sm0 md0 lg0 xl1"></div>
                <div className="line-sm p box">
                    <p className='text-right'>Jesus Jimenez Santos
                        <br />
                        CEO, Founder
                        <br />
                        760.208.0335
                        <br />
                        bagel.inbox@gmail.com
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="p border-r-1-black border-b-1-black xl2 box">
                    <p className="bold">Jan 4, 2025</p>
                    <br />
                    <p>Our team has been working tirelessly to launch an innovative new course on mobile app development. Unlike traditional courses, ours is designed specifically for individuals who aren’t pursuing a career in programming. Instead, it’s tailored for those with business ideas, equipping them with the skills to create mobile solutions that streamline operations, reduce costs, and enhance efficiency.</p>
                    <br />
                    <div className="text-right">
                        <Clickable onPress={() => { navigate('/courses') }}><p className="underline">Learn More</p></Clickable>
                    </div>
                </div>
                <div className="p border-b-1-black xl2 box">
                    <p className="bold">Dec. 25, 2024</p>
                    <br />
                    <p>Ads Mayhem has rolled out its latest updates across all platforms! Businesses can now create and manage their own coupon and ad campaigns directly through the business app. Users can explore detailed business profiles, including descriptions and exclusive coupon and ad offers. Download the app today and start discovering amazing deals!</p>
                    <br />
                    <div className="text-right">
                        <Clickable onPress={() => { navigate('/apps') }}><p className="underline">Learn More</p></Clickable>
                    </div>
                </div>
            </div>
            <div className="row border-b-1-black">
                <div className="box gap-md flex-column separate-v md2">
                    <div className="p-lg">
                        <p>.01</p>
                        <Clickable onPress={() => {
                            navigate('/projects')
                        }}><h1 className="text-xl upper line-sm thin underline">Ads Mayhem</h1></Clickable>
                        <br />
                        <p className="">An app dedicated for the sole purpose of viewing ads, coupons, and promotions in your area. No bots, no scams. Only real people with genuine interests.</p>
                    </div>
                </div>
                <div className="box gap-md flex-column separate-v md2">
                    <div className="p-lg">
                        <p>.02</p>
                        <Clickable onPress={() => {
                            navigate('/programs')
                        }}>
                            <h1 className="text-xl upper line-sm thin underline">Blocks to Bytes</h1>
                        </Clickable>
                        <br />
                        <p className="">A brand new approach to learning programming! This innovative method combines kinetic and digital learning, empowering students to create games, programs, and websites by seamlessly integrating physical and digital coding blocks.</p>

                    </div>
                </div>
                <div className="box gap-md flex-column separate-v md2">
                    <div className="p-lg">
                        <p>.03</p>
                        <Clickable onPress={() => {
                            navigate('/programs')
                        }}>
                            <h1 className="text-xl upper line-sm thin underline">Pet Grooming Edu</h1>
                        </Clickable>
                        <br />
                        <p className="">San Diego Grooming Academy has teamed up with Innovative Internet Creations to develop an exceptional student-teacher portal. This platform streamlines lessons, homework, tests, and grading, making it ideal for students aspiring to become pet groomers and instructors looking to establish their own pet grooming schools.</p>

                    </div>
                </div>
                <div className="box gap-md flex-column separate-v md2">
                    <div className="p-lg">
                        <p>.04</p>
                        <Clickable onPress={() => {
                            navigate('/programs')
                        }}>
                            <h1 className="text-xl upper line-sm thin underline">Genki Pocket Manga</h1>
                        </Clickable>
                        <br />
                        <p className="">It’s time to elevate your manga reading experience! Explore a remarkable collection of manga comics created by independent artists and small studios from Japan, the US, and Mexico. For the first time, all manga is expertly formatted for mobile devices, making reading easier, more enjoyable, and perfect for life on the go.</p>

                    </div>
                </div>

            </div>
            <div className="p">
                <h1 className="spacing-sm normal">Check out some of our latest projects..</h1>
                <br />
                <div className="row gap">

                    <Clickable onPress={() => {
                        navigate('/programs')
                    }}>
                        <img src={img4} style={{ width: '100%', height: 'auto' }} className="radius" />
                    </Clickable>
                    <Clickable onPress={() => {
                        navigate('/projects')
                    }}>
                        <img src={img1} style={{ width: '100%', height: 'auto' }} className="radius" />
                    </Clickable>
                    <Clickable onPress={() => {
                        navigate('/projects')
                    }}>
                        <img src={img2} style={{ width: '100%', height: 'auto' }} className="radius" />
                    </Clickable>
                    <Clickable onPress={() => {
                        navigate('/projects')
                    }}>
                        <img src={img3} style={{ width: '100%', height: 'auto' }} className="radius" />
                    </Clickable>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}