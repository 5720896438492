import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import img1 from '../IMAGES/blockstobytes-logo.png'

export function Programs() {
    return <div className="space">
        <Navigation />

        <div className="main">
            {/*  */}
            <div className="row p box">
                <h1 className="upper text-lg line-sm">Our Programs</h1>
            </div>
            <div className="row p box border-b-1-black">
                <p className="md2 box">We are proud members of an innovative society, driven by the vision of empowering individuals and advancing their potential. Our mission is to develop programs that leverage our expertise to educate, inspire, and solve real-world challenges. Each program is uniquely designed with a specific purpose, yet they all share a common goal: to help people grow smarter, stronger, and more capable in every area of life.</p>
            </div>
            {/*  */}
            <div className="row gap-lg box">
                <div className="p-lg md2 box">
                    <p>/1.</p>
                    <h2>Blocks to Bytes</h2>
                    <p><span className="blue bold">Blocks to Bytes</span> is a revolutionary approach to learning programming concepts while building real-world applications. Our vision is to create a versatile learning method suitable for everyone—from elementary school students to college learners—by combining tactile, hands-on experiences with engaging virtual tools.
                        <br /><br />
                        The program uses small, color-coded wooden blocks with characters and symbols that represent programming concepts and components. This hands-on approach sparks creativity, as students are only limited by their imagination. As they progress, the curriculum introduces increasingly complex concepts, enabling them to design more intricate programs.
                        <br /><br />
                        Students can create games like Battleship, Uno, or Trouble, as well as build database systems such as tables, graphs, and more. The program also includes API blocks and components to connect with external services, demonstrating how solutions can integrate seamlessly with the digital world.
                        <br /><br />
                        The possibilities are truly endless, and our thoughtfully designed activities make programming both fun and accessible for everyone. With Blocks to Bytes, we aim to inspire the next generation of innovators to explore, create, and excel.</p>
                </div>
                <div className="lg1 xl2 p-lg box">
                    <img src={img1} style={{ width: '100%', height: 'auto', maxWidth: 450 }} className="contain box" />
                </div>
            </div>
        </div>

        <Footer />
    </div>
}