import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-library/src/App.css'
import { BrowserRouter, Routes, Route } from "react-router";
import { Home } from './PAGES/Home';
import { Apps } from './PAGES/Apps';
import { AppPolicies } from './PAGES/AppPolicies';
import { Projects } from './PAGES/Projects';
import { AboutUs } from './PAGES/AboutUs';
import { Contact } from './PAGES/Contact';
import { Courses } from './PAGES/Courses';
import { CourseDetails } from './PAGES/COURSES/CourseDetails';
import { CourseVideo } from './PAGES/COURSES/CourseVideo';
import { CourseLogin } from './PAGES/COURSES/CourseLogin';
import { CourseUpload } from './PAGES/COURSES/CourseUpload';
import { CourseSignUp } from './PAGES/COURSES/CourseSignup';
import { CoursePost } from './PAGES/COURSES/CoursePost';
import { CourseForum } from './PAGES/COURSES/CourseForum';
import { Programs } from './PAGES/Programs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/apps" element={<Apps />} />
      <Route path="/app-policies" element={<AppPolicies />} />
      <Route path="/courses" element={<Courses />} />
      <Route path="/course-forum" element={<CourseForum />} />
      <Route path="/course/:courseId" element={<CourseDetails />} />
      <Route path="/lesson/:lessonId" element={<CourseVideo />} />
      <Route path="/course-login" element={<CourseLogin />} />
      <Route path="/course-signup" element={<CourseSignUp />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/course-upload-7583685288" element={<CourseUpload />} />
      <Route path="/course-post-7583685288" element={<CoursePost />} />
    </Routes>
  </BrowserRouter>
);
