import { useNavigate } from "react-router";
import { Clickable } from "../UTILITIES/Clickable";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";
import { useEffect } from "react";

export function Apps() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Mobile Apps - Innovative Internet Creations'
    }, [])

    return <div className="space">
        <Navigation />
        <div className="main">
            <div className="row p box">
                <h1 className="upper text-lg line-sm">Our Mobile Applications</h1>
            </div>
            <div className="row p box gap border-1-b-black">
                <div className="md2">
                    <p className="text-lg blue bold upper">Ads Mayhem</p>
                    <br />
                    <p>Ads should appear where they are welcome—not forced into spaces where they’re unwelcome. Ads Mayhem provides a dedicated platform for businesses to showcase their ads in a place designed for people who actively want to see them. This ensures that potential customers are genuinely interested, increasing the likelihood of turning views into real conversions. Unlike other platforms, Ads Mayhem eliminates bots, scammers, and spam accounts, offering a space filled with real people and authentic engagement.</p>
                </div>
                <div className="sm0 md1 lg0 xl1"></div>
                <div className="lg1">
                    <Clickable onPress={() => { window.open('https://play.google.com/store/apps/details?id=com.iicdev.AdsMahem&hl=en_US', '_blank') }}>
                        <p className="text-right light-blue">Ads Mayhem (Google Play)</p>
                    </Clickable>
                    <Clickable onPress={() => { window.open('https://apps.apple.com/us/app/ads-mayhem/id6737462117', '_blank') }}>
                        <p className="text-right gray">Ads Mayhem (Apple)</p>
                    </Clickable>
                    <br />
                    <Clickable onPress={() => { window.open('https://play.google.com/store/apps/details?id=com.iicdev.AdsMahemBusiness', '_blank') }}>
                        <p className="text-right light-blue">Ads Mayhem Business (Google Play)</p>
                    </Clickable>
                    <Clickable onPress={() => { window.open('https://apps.apple.com/us/app/ads-mayhem-business/id6737673889', '_blank') }}>
                        <p className="text-right gray">Ads Mayhem Business (Apple)</p>
                    </Clickable>
                    <br />
                    <br />
                    <Clickable onPress={() => { navigate('/app-policies#ads-mayhem') }}>
                        <p className="text-right underline">App Policy</p>
                    </Clickable>
                </div>
            </div>

            <div className="row p box gap border-1-b-black">
                <div className="md2">
                    <p className="text-lg blue bold upper">Practice Now!</p>
                    <br />
                    <p>A strong connection between a student and teacher is essential for developing musical skills. Consistent and focused practice is key to mastering an instrument, but without proper guidance, progress can slow or stall. Practice Now! bridges this gap by providing a mobile platform that enhances the student-teacher relationship. It offers tools for managing assignments, organizing practice schedules, accessing resources, and tracking achievements. By streamlining communication and fostering better organization, the app helps students stay motivated and on course to reach their musical potential.</p>
                </div>
                <div className="sm0 md1 lg0 xl1"></div>
                <div className="lg1">
                    <Clickable onPress={() => { window.open('https://apps.apple.com/us/app/practice-now/id1612123384', '_blank') }}>
                        <p className="text-right light-blue">Practice Now! (Apple)</p>
                    </Clickable>
                    <br />
                    <br />
                    <Clickable onPress={() => { navigate('/app-policies#ads-mayhem') }}>
                        <p className="text-right underline">App Policy</p>
                    </Clickable>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}