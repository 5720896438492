import { useEffect } from "react";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";

export function AppPolicies() {

    useEffect(() => {
        document.title = 'App Policies - Innovative Internet Creations'
    }, []);

    return <div className="space">
        <Navigation />

        <div className="main box">
            <div id="ads-mayhem" className="p box border-b-1-black">
                <h1 className="upper blue box">Ads Mayhem</h1>
                <p className="text-xs box">Effective Date: 12/13/2024
                    <br />
                    This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application (Ads Mayhem). By using the App, you agree to the terms of this Privacy Policy.</p>
                <br />
                <p className="bold box">1. Information We Collect</p>
                <p className="box">When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                <br />
                <p className="bold box">2. How We Use Your Information</p>
                <p className="box">2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                    <br />
                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.</p>
                <br />
                <p className="bold box">3. Sharing Your Information</p>
                <p className="box">We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order..</p>
                <br />
                <p className="bold box">4. Data Security</p>
                <p className="box">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                <br />
                <p className="bold box">5. Children's Privacy</p>
                <p className="box">Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.</p>
                <br />
                <p className="bold box">6. Changes to this Privacy Policy</p>
                <p className="box">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <p className="bold box">7. Contact Us</p>
                <p className="box">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span className="bold">bagel.inbox@gmail.com</span></p>
                <br />
            </div>
            <div id="edm-teachers" className="p box border-b-1-black">
                <h1 className="upper blue box">EDM Teachers</h1>
                <p className="text-xs box">Effective Date: 12/13/2024
                    <br />
                    This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application (EDM Teachers App, EDM Admin App). By using the App, you agree to the terms of this Privacy Policy.</p>
                <br />
                <p className="bold box">1. Information We Collect</p>
                <p className="box">When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                <br />
                <p className="bold box">2. How We Use Your Information</p>
                <p className="box">2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                    <br />
                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.</p>
                <br />
                <p className="bold box">3. Sharing Your Information</p>
                <p className="box">We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order..</p>
                <br />
                <p className="bold box">4. Data Security</p>
                <p className="box">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                <br />
                <p className="bold box">5. Children's Privacy</p>
                <p className="box">Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.</p>
                <br />
                <p className="bold box">6. Changes to this Privacy Policy</p>
                <p className="box">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <p className="bold box">7. Contact Us</p>
                <p className="box">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span className="bold">bagel.inbox@gmail.com</span></p>
                <br />
            </div>
            <div id="edm-admin" className="p box border-b-1-black">
                <h1 className="upper blue box">EDM Admin</h1>
                <p className="text-xs box">Effective Date: 12/13/2024
                    <br />
                    This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application (EDM Teachers App, EDM Admin App). By using the App, you agree to the terms of this Privacy Policy.</p>
                <br />
                <p className="bold box">1. Information We Collect</p>
                <p className="box">When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                <br />
                <p className="bold box">2. How We Use Your Information</p>
                <p className="box">2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                    <br />
                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.</p>
                <br />
                <p className="bold box">3. Sharing Your Information</p>
                <p className="box">We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order..</p>
                <br />
                <p className="bold box">4. Data Security</p>
                <p className="box">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                <br />
                <p className="bold box">5. Children's Privacy</p>
                <p className="box">Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.</p>
                <br />
                <p className="bold box">6. Changes to this Privacy Policy</p>
                <p className="box">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <p className="bold box">7. Contact Us</p>
                <p className="box">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span className="bold">bagel.inbox@gmail.com</span></p>
                <br />
            </div>
            <div id="edm-students" className="p box border-b-1-black">
                <h1 className="upper blue box">EDM Students</h1>
                <p className="text-xs box">Effective Date: 12/13/2024
                    <br />
                    This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application (EDM Students). By using the App, you agree to the terms of this Privacy Policy.</p>
                <br />
                <p className="bold box">1. Information We Collect</p>
                <p className="box">When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                <br />
                <p className="bold box">2. How We Use Your Information</p>
                <p className="box">2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                    <br />
                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.</p>
                <br />
                <p className="bold box">3. Sharing Your Information</p>
                <p className="box">We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order..</p>
                <br />
                <p className="bold box">4. Data Security</p>
                <p className="box">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                <br />
                <p className="bold box">5. Children's Privacy</p>
                <p className="box">Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.</p>
                <br />
                <p className="bold box">6. Changes to this Privacy Policy</p>
                <p className="box">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <p className="bold box">7. Contact Us</p>
                <p className="box">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span className="bold">bagel.inbox@gmail.com</span></p>
                <br />
            </div>
            <div id="genki-pocket-manga" className="p box border-b-1-black">
                <h1 className="upper blue box">Genki Pocket Manga</h1>
                <p className="text-xs box">Effective Date: 12/13/2024
                    <br />
                    This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application (Genki Pocket Manga). By using the App, you agree to the terms of this Privacy Policy.</p>
                <br />
                <p className="bold box">1. Information We Collect</p>
                <p className="box">When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                <br />
                <p className="bold box">2. How We Use Your Information</p>
                <p className="box">2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                    <br />
                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.</p>
                <br />
                <p className="bold box">3. Sharing Your Information</p>
                <p className="box">We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order..</p>
                <br />
                <p className="bold box">4. Data Security</p>
                <p className="box">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                <br />
                <p className="bold box">5. Children's Privacy</p>
                <p className="box">Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.</p>
                <br />
                <p className="bold box">6. Changes to this Privacy Policy</p>
                <p className="box">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <p className="bold box">7. Contact Us</p>
                <p className="box">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span className="bold">bagel.inbox@gmail.com</span></p>
                <br />
            </div>
            <div id="practice-now" className="p box border-b-1-black">
                <h1 className="upper blue box">Practice Now!</h1>
                <p className="text-xs box">Effective Date: 12/13/2024
                    <br />
                    This Privacy Policy outlines how Innovative Internet Creations ("we," "our," or "us") collects, uses, shares, and protects your personal information when you access or use our mobile application (Practice Now!). By using the App, you agree to the terms of this Privacy Policy.</p>
                <br />
                <p className="bold box">1. Information We Collect</p>
                <p className="box">When you create an account on our App, we collect your email address and display name. Additionally, when you create a new sale and list items for sale, we may collect information such as your address to facilitate pickup by buyers.</p>
                <br />
                <p className="bold box">2. How We Use Your Information</p>
                <p className="box">2.1 Providing Services: We use the information you provide to operate, maintain, and improve our App, as well as to communicate with you about your account and sales activities.
                    <br />
                    2.2 Personalization: We may use your email address to send you promotional emails, newsletters, or updates about new features or offerings that may be of interest to you. You can opt-out of receiving these communications at any time by following the unsubscribe instructions provided in the emails.</p>
                <br />
                <p className="bold box">3. Sharing Your Information</p>
                <p className="box">We may disclose your information if required to do so by law or in response to a valid legal request, such as a subpoena or court order..</p>
                <br />
                <p className="bold box">4. Data Security</p>
                <p className="box">We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                <br />
                <p className="bold box">5. Children's Privacy</p>
                <p className="box">Our App is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information without your consent, please contact us at info@happycodewebsites.com so that we can take appropriate action.</p>
                <br />
                <p className="bold box">6. Changes to this Privacy Policy</p>
                <p className="box">We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes to this Privacy Policy by posting the updated policy on our App. Your continued use of the App after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.</p>
                <br />
                <p className="bold box">7. Contact Us</p>
                <p className="box">If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span className="bold">bagel.inbox@gmail.com</span></p>
                <br />
            </div>
        </div>

        <Footer />
    </div>
}