import { Navigation } from '../../UTILITIES/Navigation'
import { Footer } from '../../UTILITIES/Footer'
import { Clickable } from '../../UTILITIES/Clickable'
import { auth_SignIn } from 'react-library/src/firebase.js'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router'
import { CourseOptions } from '../../UTILITIES/CourseOptions'
import { useEffect } from 'react'

export function CourseLogin() {
    const navigate = useNavigate()

    async function onLogin() {
        const email = document.querySelector('#tbEmail').value;
        const password = document.querySelector('#tbPassword').value;

        if (email !== "" && password !== "") {
            await auth_SignIn(auth, email, password, (user) => {
                if (user !== null) {
                    navigate('/courses')
                } else {
                    console.log("Invalid credentials. Please try again.")
                }
            })
        }
    }

    useEffect(() => {
        document.title = 'Course Login - Innovative Internet Creations'
    }, []);

    return <div className="space">
        <Navigation />
        <CourseOptions />
        <div className='main separate-v'>
            <div></div>
            <div className='flex-row full-width separate-h' style={{ maxWidth: 400, margin: '0 auto' }}>
                <div></div>
                <div className='full-width'>
                    <h1 className='text-xl normal'>Login</h1>
                    <br />
                    <p>email</p>
                    <div className='border-b-1-black'>
                        <input type='email' id='tbEmail' placeholder='Email' className='p-v full-width no-border space no-outline' />
                    </div>
                    <br />
                    <p>password</p>
                    <div className='border-b-1-black'>
                        <input type='password' id='tbPassword' placeholder='Password' className='p-v full-width no-border space no-outline' />
                    </div>
                    <br />
                    <div className='separate-h'>
                        <div></div>
                        <div>
                            <Clickable onPress={() => {
                                onLogin()
                            }}>
                                <div className='bg-black full-radius p-sm'><p className='white m-h'>log in</p></div>
                            </Clickable>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <div></div>
        </div >

        <Footer />
    </div >
}