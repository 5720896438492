import { useEffect } from "react";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";

export function AboutUs() {

    useEffect(() => {
        document.title = 'About Us - Innovative Internet Creations'
    }, []);

    return <div className="space">
        <Navigation />

        <div className="main">
            <div className="row border-b-1-black">
                <div className="p border-black box">
                    <p className="line-sm text-md">We make websites, apps, and solutions designed for the future.</p>
                </div>
                <div className="sm0 md0 lg1 xl1"></div>
                <div className="sm0 md0 lg0 xl1"></div>
                <div className="line-sm p box">
                    <p className='text-right'>Jesus Jimenez Santos
                        <br />
                        CEO, Founder
                        <br />
                        760.208.0335
                        <br />
                        bagel.inbox@gmail.com
                    </p>
                </div>
            </div>

            <div className="row p box gap border-b-1-black">
                <h1 className="upper text-lg line-sm md2">Our Mission</h1>
                <p className="md2">Our mission is to deliver innovative websites, apps, and software solutions that empower businesses, organizations, and communities, using cutting-edge technology to create meaningful connections and drive progress for people of all backgrounds.</p>
            </div>
            <div className="row p box gap border-b-1-black">
                <h1 className="upper text-lg line-sm md2">Who are we?</h1>
                <p className="md2">Innovative Internet Creations was founded by <b>Jesus Jimenez Santos</b> in San Diego, California, with a vision to shape the future through groundbreaking technology. What began as innovative software solutions quickly evolved into solutions designed for people. Everyone deserves technology that solves real-life problems with simplicity and ease—eliminating confusion and frustration.
                    <br />
                    <br />
                    Our commitment to creating cutting-edge, user-friendly products sets us apart from companies that prioritize profits over people. At Innovative Internet Creations, we are driven by the mission to deliver solutions that truly make a difference in daily lives.</p>
            </div>
            <div className="row p box gap border-b-1-black">
                <h1 className="upper text-lg line-sm md2">Our Expansion</h1>
                <p className="md2">We are rapidly expanding to other countries, partnering with professionals and businesses ready to take the next step towards automation in their logistics. Whether it’s shipping companies or small local restaurants, we are committed to reaching out globally and finding innovative ways to help businesses streamline their operations and thrive in an increasingly automated world.</p>

            </div>
            <br /><br />
            <div className="p-lg">
                <b>Now established in Tokyo, Japan | Bangkok, Thailand | Guadalajara, Mexico.</b>
            </div>
        </div>

        <Footer />
    </div>
}