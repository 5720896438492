import { useEffect } from "react";
import { Footer } from "../UTILITIES/Footer";
import { Navigation } from "../UTILITIES/Navigation";

export function Contact() {

    useEffect(() => {
        document.title = 'Contact Us - Innovative Internet Creations'
    }, []);

    return <div className="space">
        <Navigation />
        <div className="main">
            {/*  */}
            <div className="row p box">
                <div className="md2">
                    <h1 className="upper text-lg line-sm">Contact Us</h1>
                    <p>For any questions or information, feel free to contact us via email or phone. To help us assist you more efficiently, please include “Inquiry” in the subject line along with your message.</p>
                </div>
                <div className="text-right xl2">
                    <h2>bagel.inbox@gmail.com</h2>
                    <h2>+1 760.208.0335</h2>
                </div>
            </div>
            <div className="text-right">
                <p className="bold">Based in San Diego, CA, US.<br />Tokyo, Japan.<br />Bangkok, Thailand.<br />Guadalajara, Mexico.</p>
            </div>
        </div>
        <Footer />
    </div>
}