import { useNavigate, useParams } from "react-router";
import { Footer } from "../../UTILITIES/Footer";
import { Navigation } from "../../UTILITIES/Navigation";
import { useEffect, useState } from "react";
import { Loading } from "../../UTILITIES/Loading";
import { auth_CheckUser, firebase_GetAllDocumentsQueried, firebase_GetDocument } from "react-library/src/firebase";
import { auth, db } from "../../firebase";
import { sortArrayByObj } from 'react-library/src/FUNCTIONS/arrays.js'
import { Clickable } from "../../UTILITIES/Clickable";
import { UserOptions } from "../../UTILITIES/UserOptions";
import { server_POST } from 'react-library/src/SERVER/server.js'
import { GoCheckCircleFill } from "react-icons/go";

export function CourseDetails() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { courseId } = useParams();
    const [course, setCourse] = useState(null)
    const [lessons, setLessons] = useState([])
    const [me, setMe] = useState(null)
    // 
    const [loggedIn, setLoggedIn] = useState(false);
    const [paid, setPaid] = useState(false);
    const [views, setViews] = useState([])

    async function onLoad() {
        await firebase_GetDocument(db, 'Courses', courseId, (doc) => {
            setCourse(doc);
            document.title = `${doc.name}`
        })
        await firebase_GetAllDocumentsQueried(db, 'Lessons', [
            { field: 'courseId', operator: '==', value: courseId }
        ], (docs) => {
            setLessons(docs)
        })

        await auth_CheckUser(auth, async (user) => {
            if (user !== null) {
                setLoggedIn(true)
                await firebase_GetDocument(db, 'CourseUsers', user.uid, async (doc) => {
                    setMe(doc);
                    await firebase_GetAllDocumentsQueried(db, 'CourseViews', [
                        { field: 'userId', operator: '==', value: user.uid },
                        { field: 'courseId', operator: '==', value: courseId }
                    ], (docs) => {
                        setViews(docs)
                    })
                    await firebase_GetAllDocumentsQueried(db, 'CoursePayments', [
                        { field: 'courseId', operator: '==', value: courseId },
                        { field: 'userId', operator: '==', value: user.uid }
                    ], (docs) => {
                        console.log(docs)
                        if (docs.length > 0) {
                            setPaid(true)
                        }
                    })
                })

            }
        })

    }

    useEffect(() => {
        onLoad();
    }, [])

    if (course != null) {
        return <div className="space">
            {loading && <Loading />}
            <Navigation />
            {loggedIn && <UserOptions />}
            <div className="main">

                <div className="row box p-md">
                    <div className="md2">
                        <h1>{course.name}</h1>
                        <p>{course.desc}</p>
                        <div className="separate-h">
                            <h2 className="blue">{course.total}</h2>
                            <h2 className="green">${course.price}.00</h2>
                        </div>
                    </div>
                </div>
                <br />
                <div className="">
                    {
                        sortArrayByObj(lessons, 'order').map((lesson, i) => {
                            return <div>
                                <Clickable onPress={async () => {
                                    if (course.id !== 'V9MjyflIWCQ47usUtFmL') {
                                        navigate(`/lesson/${lesson.id}`)
                                        return;
                                    }
                                    if (
                                        lesson.order == 1 ||
                                        lesson.order == 2 ||
                                        lesson.order == 7 ||
                                        lesson.order == 11 ||
                                        lesson.order == 12) {
                                        navigate(`/lesson/${lesson.id}`)
                                        return;
                                    }
                                    if (loggedIn) {
                                        if (paid) {
                                            navigate(`/lesson/${lesson.id}`)
                                        } else {
                                            console.log(lesson)
                                            // PAY HERE
                                            setLoading(true)
                                            const url = await server_POST('iic-create-payment-link', {
                                                userId: me.id,
                                                courseName: course.name,
                                                email: me.email,
                                                courseId: course.id,
                                                amount: course.price * 100,
                                                currency: 'USD',
                                                customerId: me.customerId !== undefined ? me.customerId : "",
                                                app: 'innovative-internet-creations'
                                            });
                                            window.location.href = url.url;
                                        }
                                    } else {
                                        navigate('/course-login')
                                    }
                                }}>
                                    <div className="border-b-1-black" key={i}>
                                        <div className="p separate-h">
                                            <p>{lesson.order}. {lesson.name}</p>
                                            <div className="flex-row gap vertical-center">
                                                {lesson.courseId !== 'V9MjyflIWCQ47usUtFmL' ||
                                                    lesson.order == 1 ||
                                                    lesson.order == 2 ||
                                                    lesson.order == 7 ||
                                                    lesson.order == 11 ||
                                                    lesson.order == 12 ? <p className="light-blue">free</p> : <p></p>}
                                                {
                                                    views.map((ting) => ting.lessonId).includes(lesson.id) && <GoCheckCircleFill color="#1DBF73" size={25} />
                                                }
                                                <p>{lesson.duration}</p>

                                            </div>
                                        </div>
                                    </div>
                                </Clickable>
                            </div>
                        })
                    }
                </div>
            </div>

            <Footer />
        </div>
    } else {
        return <Loading />
    }
}