import { useEffect, useState } from "react"
import { Loading } from "../../UTILITIES/Loading"
import { firebase_CreateDocument, firebase_GetAllDocuments, storage_UploadMedia } from "react-library/src/firebase"
import { db, storage } from "../../firebase"
import { Clickable } from "../../UTILITIES/Clickable"
import { randomString } from 'react-library/src/FUNCTIONS/misc.js'
import { useNavigate } from "react-router"

export function CourseUpload() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const [video, setVideo] = useState(null)

    async function onCreateLesson() {
        const name = document.querySelector('#tbName').value;
        const desc = document.querySelector('#taDesc').value;
        const note = document.querySelector('#taNote').value;
        const courseId = document.querySelector('#ddCourseId').value;
        const duration = document.querySelector('#tbDuration').value;
        const order = document.querySelector('#tbOrder').value;


        if (name == "" || desc == "" || courseId == "" || duration == "") {
            alert('Please fill out all fields in this form.')
            return;
        }

        const videoPath = `CourseVideos/MobileApps/${randomString(12)}.mp4`

        const args = {
            courseId: courseId,
            name: name,
            desc: desc.replaceAll("\n", "jjj"),
            note: note.replaceAll("\n", 'jjj'),
            duration: duration,
            videoPath: videoPath,
            order: parseInt(order)
        }
        setLoading(true)
        await storage_UploadMedia(storage, video, videoPath, async (success) => {
            if (success) {
                await firebase_CreateDocument(db, 'Lessons', randomString(25), args, (success2) => {
                    if (success2) {
                        document.querySelector('#tbName').value = ""
                        document.querySelector('#taDesc').value = ""
                        document.querySelector('#taNote').value = ""
                        document.querySelector('#tbDuration').value = ""
                        document.querySelector('#tbOrder').value = ""
                        setVideo(null)
                        setLoading(false)
                    } else {
                        alert("Something went wrong.")
                        setLoading(false)
                        return
                    }
                })
            } else {
                setLoading(false);
                alert("Something went wrong.")
                return
            }
        })

    }
    function handleVideoChange(event) {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ["video/mp4", "video/avi", "video/mkv"];
            if (!validTypes.includes(file.type)) {
                alert("Please upload a valid video file.");
                event.target.value = ""; // Clear the input
                setVideo(null); // Reset video state
                return;
            }
            setVideo(file); // Set the selected video
            console.log("Video selected:", file.name);
        }
    }
    // 
    async function onLoad() {
        await firebase_GetAllDocuments(db, 'Courses', (docs) => {
            setCourses(docs)
        })
    }

    useEffect(() => {
        onLoad()
    }, [])

    return <div className="main p space">
        {loading && <Loading />}
        <h1>Upload Lesson Video</h1>
        <br />
        <br />
        <div className="row">
            <div className="md2">
                <p>course</p>
                <select id="ddCourseId" className="p">
                    {
                        courses.map((course, i) => {
                            return <option key={i}>{course.id}</option>
                        })
                    }
                </select>
                <br />
                <p>order</p>
                <div className="border-b-1-black">
                    <input type="text" id="tbOrder" placeholder="int number" className="no-outline p-v full-width no-border space" />
                </div>
                <br />
                <p>name</p>
                <div className="border-b-1-black">
                    <input type="text" id="tbName" placeholder="ex. Essential Software Installations" className="no-outline p-v full-width no-border space" />
                </div>
                <br />
                <p>description</p>
                <div className="border-b-1-black">
                    <textarea style={{ minHeight: 300 }} id="taDesc" className="no-outline p-v space no-border full-width" placeholder="ex. Firebase is a database service purchased and managed by Google.."></textarea>
                </div>
                <br />
                <p>note</p>
                <div className="border-b-1-black">
                    <textarea style={{ minHeight: 150 }} id="taNote" className="no-outline p-v space no-border full-width" placeholder="You might get a warning. To fix do ..."></textarea>
                </div>
                <br />
                <p>duration</p>
                <div className="border-b-1-black">
                    <input type="text" id="tbDuration" placeholder="ex. 4:33" className="no-outline p-v full-width no-border space" />
                </div>
                <br />
                <p>video</p>
                <input type="file" id="fuVideo" onChange={handleVideoChange} />
                <br />
                <br />
                <div className="separate-h">
                    <div></div>
                    <div>
                        <Clickable onPress={() => {
                            onCreateLesson()
                        }}>
                            <div className="bg-black full-radius p-sm">
                                <p className="white m-h">create lesson</p>
                            </div>
                        </Clickable>
                    </div>
                </div>
            </div>
        </div>

        <br />
        <br />
    </div>
}