import { useNavigate } from "react-router";
import { Clickable } from "./Clickable";

export function Footer() {
    const navigate = useNavigate();
    return <div className="space bg-black">
        {/*  */}
        <div className="row p white">
            <Clickable onPress={() => {
                window.open('https://instagram.com/innovative_internet_creations')
            }}>
                <p>Instagram</p>
            </Clickable>
            <Clickable onPress={() => {
                window.open('https://facebook.com/innovativeinternetcreations')
            }}>
                <p>Facebook</p>
            </Clickable>
            <div className="sm0 xl1"></div>
            <Clickable onPress={() => {
                window.scrollTo(0, 0);
            }}>
                <p>back to top</p>
            </Clickable>
        </div>

        <div className="row p white line box vertical-bottom">
            <div className="">
                <Clickable onPress={() => { navigate('/') }}>
                    <h1 className="text-md">Innovative Internet Creations LLC</h1>
                </Clickable>
            </div>
            <div className="sm0 md0 xl1"><br /></div>
            <div className="sm0 md0 lg1 xl1"><br /></div>
            <p className="text-xs">&copy; copyright Innovative Internet Creations LLC. All Rights Reserved 2024.</p>
        </div>
    </div>
}